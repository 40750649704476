import { useEffect, useState } from "react";
import "./VAboutUs.css";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// photos
import office from "./../../assets/photos/office/history.jpg";
// chrono
import items from "./chronolgy.json";

import { Chrono } from "react-chrono";

const VAboutUs = () => {
    const [aboutType, setAboutType] = useState('history');
    const [aboutSwiper, setAboutSwiper] = useState(null);

    const breakpointMd = 1024;
    const [timeline, setTimeline] = useState('VERTICAL');

    const updateMedia = () => {
        if(window.innerWidth < breakpointMd){
            setTimeline('VERTICAL');
        } else{
            setTimeline('VERTICAL_ALTERNATING');
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });




    return(
        <>
            <section className="aboutUs-section">
                <h1 className="subheader-title">О нас</h1>

                <div className="aboutUs-type_selector">
                    <p 
                        onClick={()=>{
                                        setAboutType('history');
                                        aboutSwiper.slideTo(0, 1000);
                                    }}
                        className={`aboutUs-typeNav${aboutType==='history' ? " active" : ""}`}
                    >История</p>
                    <p 
                        onClick={()=>{
                                        setAboutType('chronology');
                                        aboutSwiper.slideTo(1, 1000);
                                    }}
                        className={`aboutUs-typeNav${aboutType==='chronology' ? " active" : ""}`}
                    >Хронология</p>
                    <p 
                        onClick={()=>{
                                        setAboutType('team');
                                        aboutSwiper.slideTo(2, 1000);
                                    }}
                        className={`aboutUs-typeNav${aboutType==='team' ? " active" : ""}`}
                    >Команда</p>
                </div>

                <Swiper
                    onSwiper={setAboutSwiper}
                    onSlideChange={(swiper) => {
                        switch (swiper.realIndex) {
                            case 0:
                                setAboutType('history');
                                break;
                            case 1:
                                setAboutType('chronology');
                                break;
                            case 2:
                                setAboutType('team');
                                break;
                            default:
                                break;
                        }
                    }}
                    slidesPerView={"1"}
                    centeredSlides={true}
                    spaceBetween={30}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className={`${aboutType==="history" ? "aboutUs-type_info" : "hidden"}`}>
                            <p className="mb-5">Наша компания берет свое начало с 2013 года. Изначально школа была создана как онлайн платформа по изучению английского с носителями языка по скайпу.</p>
                            <p className="mb-5">Главной особенностью школы было то, что студент мог заниматься в любое удобное время с возможностью выбора и замены преподавателя в течение всего курса без отклонения от графика выбранного курса обучения. Это достигалось за счет того, что преподаватели работали командно и сплоченно. По каждому студенту велась индивидуальная карта обучения с историей обо всех деталях и нюансах хода обучения.</p>
                            <p className="mb-5">В 2016 году была открыта первая оффлайн школа.</p>
                            <figure className="mb-5 aboutUs-office">
                                <img src={office} alt="office" />
                                <figcaption>Наш первый офис</figcaption>
                            </figure>
                            <p className="mb-5">С 2019 года, наша школа сделала основной упор на создание автоматизированной программы обучения студента под профессиональным контролем преподавателя.</p>
                            <p>С 2022 года платформа была изменена на автоматизированное, полностью самостоятельное обучение, с возможностью дополнительного контроля с стороны учителя.</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className={`${aboutType==="chronology" ? "aboutUs-type_info" : "hidden"}`}>
                            <Chrono 
                                items={items} 
                                mode={timeline}
                                hideControls
                                activeItemIndex={6}
                            />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className={`${aboutType==="team" ? "aboutUs-type_info" : "hidden"}`}>
                            <p>Teacher Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                    </SwiperSlide>
                </Swiper>

                


            </section>
        </>
    )
}

export default VAboutUs;

