import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logout } from './../features/user';

const baseQuery = fetchBaseQuery({
    baseUrl: 'http://localhost:8000',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.access_token;
        if(token){
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers;
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.originalStatus === 403){
        console.log('sending refresh token');
        // send refresh token to get new access token
        const refreshResult = await baseQuery('/api/refresh', api, extraOptions);
        console.log(refreshResult);
        if(refreshResult?.data){
            const user = api.getState().auth.user;
            // store the new token
            api.dispatch(setCredentials({ ...refreshResult.data, user }));
            // retry the originall query with new access token
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
}

export const apiSlice = createApi({
    reducerPath: 'apiSlice',
    tagTypes: ['Comments'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        // LOGIN
        
        // SIGN UP
        userSignup: builder.mutation({
            query: (body) => ({
                url: '/api/signup',
                method: 'POST',
                body
            })
        }),

        getGoods: builder.query({
            query: (limit = '') => `/api/goods${limit && `?_limit=${limit}`}`,
            providesTags: (result, error, arg) =>
                result
                ? [...result.map(({ id }) => ({ type: 'Comments', id })), 'Comments']
                : ['Comments'],
        }),
        addComment: builder.mutation({
            query: (body) => ({
                url: '/api/articles/learn-react/add-comment',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Comments']
        }),
        updateComment: builder.mutation({
            query: ({ id, ...rest }) => ({
                url: `/api/articles/learn-react/${id}`,
                method: 'PUT',
                body: rest,
            }),
            invalidatesTags: ['Comments']
        }),
        deleteComment: builder.mutation({
            query: (id) => ({
                url: `/api/articles/learn-react/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Comments']
        })
    })
})

export const { 
                useUserSignupMutation,
                useGetGoodsQuery, useAddCommentMutation ,useUpdateCommentMutation, useDeleteCommentMutation 
            } = apiSlice;