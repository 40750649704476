import "./C404NotFound.css";
import { FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const C404NotFound = () => {
    return(
        <>
            <div className="block-404_error">
                <h1 className="text-5xl">404 ERROR</h1>
                <h2 className="text-3xl">page not found</h2>
                <NavLink to={"/"}>
                    <button className="inline-flex items-center px-4 py-2 bg-blue-500 rounded mt-5"><FaHome className="mr-2" />Go Back Home</button>
                </NavLink>
            </div>
        </>
    )
}

export default C404NotFound;