import './PNavBar.css';

// ICONS
import { FaUserAlt } from 'react-icons/fa';
import { BiMenuAltLeft } from 'react-icons/bi';
import { IoMdNotifications } from 'react-icons/io';

// REDUX
import { useDispatch, useSelector } from "react-redux";
    // ASIDE
import { setAsideFixed } from "../../features/aside";
    // OFFCANVAS USER
import { setOffcanvasUserToggleVisibility } from "./../offcanvas/POffcanvasUserSlice";
    // OFFCANVAS NOTIFICATIONS
import { setOffcanvasNotificationsToggleVisibility } from './../offcanvas/POffcanvasNotificationsSlice';

const PNavBar = () => {
    const dispatch = useDispatch();
    // ASIDE
    const { asideFixed } = useSelector( (state) => state.aside);
    // OFFCANVAS USER
    const { offcanvasUserVisibility } = useSelector( (state) => state.offcanvasUser);
    // OFFCANVAS NOTIFICATIONS
    const { offcanvasNotificationsVisibility } = useSelector( (state) => state.offcanvasNotifications);
    
    return(
        <>
            <nav className={"noselect portal-nav-main justify-between" + (asideFixed ? " aside-fixed" : "")}>
                {/* LEFT */}
                <div className='text-white'>
                    <div id='asideNav'
                            onClick={() => dispatch(setAsideFixed(!asideFixed)) } 
                            className='portal-nav-icon text-xl portal-nav-btn-aside' > <BiMenuAltLeft /> 
                    </div>
                    
                </div>

                {/* RIGHT */}
                <div className='flex text-white'>
                    {/* NOTIFICATIONS */}
                    <div 
                        id='offcanvasNotifications' 
                        onClick={() => dispatch(setOffcanvasNotificationsToggleVisibility(!offcanvasNotificationsVisibility)) }  
                        className='portal-nav-icon'>
                            <IoMdNotifications />
                    </div>

                    {/* USER */}
                    <div 
                        id='offcanvasUser' 
                        onClick={() => dispatch(setOffcanvasUserToggleVisibility(!offcanvasUserVisibility)) }  
                        className='portal-nav-icon'>
                            <FaUserAlt />
                    </div>
                </div>
            </nav>
        </>
    )
}


export default PNavBar;
