import "./VAuth.css";
import { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

// redux
import { useDispatch } from 'react-redux';
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
// custom
import { validateEmail } from "../../customHooks/validations";

const VAuth = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');

    const emailRef = useRef();
    const errRef = useRef();

    // useEffect( () => {
    //     emailRef.current.focus();
    // }, [])

    useEffect( () => {
        setErrorMsg('');
    }, [email, password])

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const from = location.state?.from?.pathname || "../dashboard";

    const [login, {data: userData, isSuccess, isError, error}] = useLoginMutation();
    
    useEffect( () => {
        if(isSuccess){
            if(userData.status==='ok'){
                setEmail('');
                setPassword('');
                dispatch(setCredentials(userData?.data?.access_token));
                navigate(from, { replace: true })
            }
            
        } else if(isError){
            setErrorMsg(<li>{error.data.message}</li>);
            errRef.current.focus();
        }
    }, [isSuccess, isError, error, navigate, userData, dispatch, from])

    const handleSubmit = async (e) => {
        e.preventDefault();
        var errors = [];
        
        // EMAIL VALIDATION
        if(!email){
            errors.push('email cannot be empty');
        }else{     
            var isValidEmail = validateEmail(email);
            if(!isValidEmail){
                errors.push('invalid email');
            }
        }

        // PASSWORD VALIDATION
        if(!password){
            errors.push('password cannot be empty');
        }else{
            var isEnoughLengthPassword = password.length>=6;
            if(!isEnoughLengthPassword){
                errors.push('password must have at least 6 characters');
            }
        }
            
        if(isValidEmail && isEnoughLengthPassword){
            // VALID FORMAT
            await login({email, password}).unwrap();
        }else{
            let list_of_errors = errors.map( (txt, i) => (
                    <li key={i}>{txt}</li>
                )
            )
            setErrorMsg(list_of_errors); 
            errRef.current.focus();
            console.log(1)
        }
        
    }

    return(
        <>
            <section className="login-section">
                <div className="login-pattern"></div>
                <div className="login-frame">
                    <h1>
                        <span className="text-5xl company-name-arc-color">Arc</span>
                        <span className="text-5xl company-name-teach-color">Teach</span>
                    </h1>
                    <p className="text-xl mt-2">Добро пожаловать</p>
                    <ul 
                        ref={errRef} 
                        aria-live="assertive" 
                        className="text-red-500">
                            {errorMsg}
                    </ul>

                    <form onSubmit={handleSubmit} className="flex flex-col mt-5">
                        <input 
                            className="login-email_input my-3"
                            ref={emailRef}
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            type={'email'} 
                            placeholder={'эл. почта'}
                            required
                        />
                        <input
                            autoComplete='new-password'
                            className="login-password_input my-3"
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            type={'password'} 
                            placeholder={'пароль'}
                            required
                        />
                        
                        <button 
                            className="login-btn-login py-2 mt-2">
                            Войти
                        </button>
                        <label className="flex justify-center items-center mt-2">
                            <input className="mr-2" type="checkbox"
                                defaultChecked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                            />
                            Запомнить меня на этом устройстве
                        </label>
                        
                    </form>

                    <p className="text-gray-600 mt-10"> Забыли пароль? <NavLink to={'/recover'} className="hover:text-black underline" >Восстановить пароль</NavLink></p>
                    <p className="text-gray-600 mt-2"> Нет аккаунта? <NavLink to={'/sign-up'} className="hover:text-black underline" >Создать аккаунт</NavLink></p>
                </div>
            </section>
            
        </>
    )
}

export default VAuth;