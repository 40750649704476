import React, { useState } from "react";
import "./VHome.css";

// REDUX
import { useDispatch } from "react-redux";

import { useAddCommentMutation, useGetGoodsQuery } from "../../app/apiSlice";

const Vhome = () => {
    const dispatch = useDispatch();

    //
    const [addComment, {isError}] = useAddCommentMutation();
    if(isError){console.log(isError)}
    const [username, setUsername] = useState('');
    const [text, setText] = useState('');

    const handleAddComment = async () => {
        if(username && text){
            let body = {username: username, text: text};
            await addComment(body).unwrap();
            setUsername('');
            setText('');
        }
    }

    //
    const [limit, setLimit] = useState('');
    
    const { data, error, isLoading, isFetching, isSuccess, refetch } = useGetGoodsQuery(limit);
    if(isLoading) return <h1>loading...</h1>;

    // if(error) console.log(error)
    // console.log(data);
    return(
        <>
            <section className="main flex h-screen">
                        <div className="m-auto">
                            <h1 className="text-3xl"><span>Arc</span><span>Teach</span></h1>
                            <h2>"We Know You Can"</h2>
                            <p>Образовательный Учебный Портал</p>
                            <p>Единственный путь стать умнее – играть с более сильным соперником</p>
                        </div>
            </section>

            <section className="main flex h-screen">
                        <div className="m-auto">
                            <h1 className="text-3xl"><span>Arc</span><span>Teach</span></h1>
                            <h2>"We Know You Can"</h2>
                            <p>Образовательный Учебный Портал</p>
                            <p>Единственный путь стать умнее – играть с более сильным соперником</p>
                        </div>
            </section>
            <section className="p-4">
                <h1>User:</h1>

               
                <button className="bg-gray-500 rounder px-4 py-2 rounded">Log In</button>
                <button className="bg-gray-500 rounder px-4 py-2 rounded">Log Out</button>
            </section>

            <section className="p-4">
                <button onClick={() => setLimit(1)} className="bg-gray-500 rounder px-4 py-2 rounded">Limit 1</button>
                {data?.map( (record, i) => {
                    return (
                        <div key={i}>
                            <h1 className="font-bold" >{record.name}</h1>
                            <p>Comments:</p>
                            <ul>
                                {record.comments.map((comment, j)=>{
                                    return <li key={j} className="ml-5">{comment.username}: {comment.text}</li>
                                })}
                                
                            </ul>
                        </div>
                    )
                })}
            </section>

            <section className="p-4">
                <input onChange={(e) => {setUsername(e.target.value)}} value={username} type='text' placeholder="Name"/>
                <input onChange={(e) => {setText(e.target.value)}} value={text} type='text' placeholder="Text"/>
                <button onClick={handleAddComment} className="bg-gray-500 px-4 py-2 rounded ml-3">Add Comment</button>
            </section>
        </>
    )
}
export default Vhome