import "./VRecover.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const VRecover = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    return(
        <section className="recover-section">
            <div className="recover-pattern"></div>
                <div className="signup-frame">
                    <h1>
                        <span className="text-5xl company-name-arc-color">Arc</span>
                        <span className="text-5xl company-name-teach-color">Teach</span>
                    </h1>
                    <p className="text-xl mt-2">Восстановление пароля</p>
                    {errorMsg}
                    <form className="flex flex-col">
                        <input 
                            autoComplete='new-password' 
                            className='recover-email_input my-3' 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            type={'email'} 
                            placeholder={'эл. почта'}/>

                        <button 
                            className="recover-btn-recover py-2 mt-2">
                                Восстановить
                        </button>

                        <p className="text-gray-600 mt-10">Уже есть аккаунт? <NavLink to={'/login'} className="hover:text-black underline" >Вход</NavLink></p>
                        <p className="text-gray-600 mt-2"> Нет аккаунта? <NavLink to={'/sign-up'} className="hover:text-black underline" >Создать аккаунт</NavLink></p>
                    </form>
                </div>
            </section>
    )
}

export default VRecover;