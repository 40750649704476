import { NavLink } from "react-router-dom";
import "./VFooter.css";
import { FaTwitter, FaVk, FaInstagram, FaFacebookF } from "react-icons/fa";

const VFooter = () => {
    return(
        <footer className="flex flex-col items-center py-5">
            <h2>
                <span className="company-name-arc-color text-3xl">ARC</span>
                <span className="company-name-teach-color text-3xl">TEACH</span>
            </h2>
            <p className="text-base italic mb-5">"We Know You Can"</p>

            <div className="flex flex-col mb-7">
                <NavLink className="my-1" to={'/for-students'}>For Students</NavLink>
                <NavLink className="my-1" to={'/for-parents'}>For Parents</NavLink>
                <NavLink className="my-1" to={'/for-teachers'}>For Teachers</NavLink>
            </div>

            <div className="flex">                
                {/* INSTAGRAM */}
                <a href="https://www.instagram.com/arcteach">
                    <FaInstagram size='20' className="mx-4 fill-gray-500 hover:fill-black cursor-pointer" />
                </a>

                {/* VK */}
                <a href="https://vk.com/arcteach">
                    <FaVk size='20' className="mx-4 fill-gray-500 hover:fill-black cursor-pointer" />
                </a>

                {/* FACEBOOK */}
                <a href="https://www.facebook.com/arcteach">
                    <FaFacebookF size='20' className="mx-4 fill-gray-500 hover:fill-black cursor-pointer" />
                </a>

                {/* TWITTER */}
                <a href="https://twitter.com/arcteach_school">
                    <FaTwitter size='20' className="mx-4 fill-gray-500 hover:fill-black cursor-pointer" />
                </a>
            </div>

            <p className="mt-5 hover:underline"><NavLink to={'terms-of-use'}>Terms of Use</NavLink></p>
            <p className="text-gray-600">© 2013-{new Date().getFullYear()} ArcTeach. All Rights Reserved.</p>
        </footer>
    )
}

export default VFooter;