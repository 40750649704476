import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";

import './App.css';
import "./comp_visitor/VCommon.css";



/////////////////////////////////// COMMON  ///////////////////////////////////
// 404 ERROR
import C404NotFound from "./comp_common/404_not_found/C404NotFound";
import CScrollToTop from "./comp_common/CScrollToTop";

/////////////////////////////////// VISITOR ///////////////////////////////////
// Partials
import VNavBar from "./comp_visitor/partials/VNavBar";
import VFooter from "./comp_visitor/partials/VFooter";
// Pages
import Vhome from './comp_visitor/home/Vhome';
import VHowItWorks from "./comp_visitor/how_it_works/VHowItWorks";
import VAuth from "./comp_visitor/auth/VAuth";
import VSignUp from "./comp_visitor/sign_up/VSignUp";
import VElementsOfArcteach from "./comp_visitor/elements-of-arcteach/VElementsOfArcteach";
import VFaq from "./comp_visitor/faq/VFaq";
import VRecover from "./comp_visitor/recover/VRecover";
import VPrice from "./comp_visitor/price/VPrice";
import VAboutUs from "./comp_visitor/about_us/VAboutUs";

/////////////////////////////////// PORTAL ///////////////////////////////////
// Private Route
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
// Partials
import PNavBar from "./comp_portal/partials/PNavBar";
import PSideMenu from "./comp_portal/partials/PSideMenu";
// Pages
import PDashboard from "./comp_portal/dashboard/PDashboard";
// OffCanvas
import POffcanvasUser from "./comp_portal/offcanvas/POffcanvasUser";
import POffcanvasNotifications from "./comp_portal/offcanvas/POffcanvasNotifications";




// import { useSelector } from "react-redux";
// import { selectCurrentToken } from "./features/auth/authSlice";


function VisitorLayoutWFooter(){
  return(
        <>
          <VNavBar />
          <Outlet />
          <VFooter />
        </>
  )
}

function VisitorLayoutNoFooter(){
  return(
        <>
          <VNavBar />
          <Outlet />
        </>
  )
}

function PortalLayout(){
  return(
    <>
      <PNavBar />
      <PSideMenu />
      <Outlet />
      
      <POffcanvasUser />
      <POffcanvasNotifications />
    </>
  )
}



function App() {
  return (
    <BrowserRouter> 
          <CScrollToTop />
          <Routes>

            <Route path='*' element={<C404NotFound />} />

            <Route path="/">
              {/* VISITOR WITH FOOTER */} 
              <Route element={<VisitorLayoutWFooter />}>
                <Route index element={<Vhome />} />
                <Route path="how-it-works" element={<VHowItWorks />}/>
                <Route path="price" element={<VPrice />}/>
                <Route path="elements-of-arcteach" element={<VElementsOfArcteach />}/>
                <Route path="about" element={<VAboutUs />}/>  
                <Route path="courses" element={<VAboutUs />}/> 
                <Route path="faq" element={<VFaq />}/>   
                <Route path="contacts" element={<VAboutUs />}/>  
              </Route>

              {/* VISITOR NO FOOTER */}
              <Route element={<VisitorLayoutNoFooter />} >
                <Route element={<PersistLogin />}>
                  <Route path="login" element={<VAuth />}/>
                </Route>

                <Route path="sign-up" element={<VSignUp />}/>
                <Route path="recover" element={<VRecover />}/>
              </Route>

              {/* PORTAL */}
              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                  <Route element={<PortalLayout />}>
                    <Route path="dashboard" element={<PDashboard />} />
                    <Route path="test" element={<PDashboard />} />
                  </Route>
                </Route>
              </Route>

            </Route>

          </Routes>
    </BrowserRouter>
    
  );
}

export default App;
