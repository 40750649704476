import { createSlice } from '@reduxjs/toolkit'

export const asideSlice = createSlice({
    name: 'aside',
    initialState: {
        asideFixed: false,
        asideOpen: false,
    },
    reducers: {
        setAsideFixed: (state, action) =>  {
                                            state.asideFixed = action.payload
                                        },
        setAsideOpen: (state) =>   {
                                    state.asideOpen = true
                                },
        setAsideClosed: (state) =>  {
                                    state.asideOpen = false
                                },
    },
})


// Action creators are generated for each case reducer function
export const { setAsideFixed, setAsideOpen, setAsideClosed } = asideSlice.actions

// useSelector()
export const selectAside = (state) => state.aside;

export default asideSlice.reducer