import { createSlice } from "@reduxjs/toolkit"; 
import decode from "jwt-decode";

const initialStateValue = {
                            isVerified: false,
                            email: '',
                            info: false
                        };

export const userSlice = createSlice({
    name: 'user',
    initialState: initialStateValue,
    reducers: {
        
    }
})

// Action creators are generated for each case reducer function
export const { setCredentials, logout } = userSlice.actions;

export default userSlice.reducer;