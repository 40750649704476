import "./VElementsOfArcteach.css";
import { useState } from "react";


// FIRE
import {ReactComponent as FireMain } from "./../../assets/elements/fireMain.svg";
import FireWoman from "./../../assets/elements/fireWoman.svg";
import FireMan from "./../../assets/elements/fireMan.svg";
import FireAnimal from "./../../assets/elements/fireAnimal.svg";

// WATER
import {ReactComponent as WaterMain } from "./../../assets/elements/waterMain.svg";
import WaterWoman from "./../../assets/elements/waterWoman.svg";
import WaterMan from "./../../assets/elements/waterMan.svg";
import WaterAnimal from "./../../assets/elements/waterAnimal.svg";

// EARTH
import {ReactComponent as EarthMain } from "./../../assets/elements/earthMain.svg";
import EarthWoman from "./../../assets/elements/earthWoman.svg";
import EarthMan from "./../../assets/elements/earthMan.svg";
import EarthAnimal from "./../../assets/elements/earthAnimal.svg";

// AIR
import {ReactComponent as AirMain } from "./../../assets/elements/airMain.svg";
import AirWoman from "./../../assets/elements/airWoman.svg";
import AirMan from "./../../assets/elements/airMan.svg";
import AirAnimal from "./../../assets/elements/airAnimal.svg";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";


const VElementsOfArcteach = () => {
    const [selected, setSelected] = useState('fire');
    const [gender, setGender] = useState('man');
    const [animal, setAnimal] = useState('fox');
    const [characterSwiper, setCharacterSwiper] = useState(null);
    const [animalSwiper, setAnimalSwiper] = useState(null);

    const [elementTitle, setElementTitle] = useState('Огонь');



    return(
        <>
            
            <section className="elements-section">
                <h1 className="subheader-title">Стихии <span className="company-name-arc-color">Arc</span><span className="company-name-teach-color">Teach</span></h1>
                
                <div className="elements-block">
                    
                    <p className="my-5">В ArcTeach есть 4 элемента: огонь, вода, земля и воздух. У каждого нации элемента есть свои особенности.</p>
                    <p className="my-5">Каждый пользователь автоматически относится к одной из нации элементов в зависимости от даты рождения.</p>
                    

                    <div className="test2">
                        <div className="flex justify-between">
                            <FireMain 
                                onClick={()=> {
                                                setSelected('fire')
                                                setElementTitle('Огонь')
                                                characterSwiper.slideTo(0, 1000);
                                            }}
                                className={`element-icon${selected==='fire' ? "" : " element-icon-inactive"}`} />
                            <WaterMain
                                onClick={()=> {
                                    setSelected('water')
                                    setElementTitle('Вода')
                                    characterSwiper.slideTo(1, 1000);
                                }}
                                className={`element-icon${selected==='water' ? "" : " element-icon-inactive"}`} />
                            <EarthMain
                                onClick={()=> {
                                    setSelected('earth')
                                    setElementTitle('Земля')
                                    characterSwiper.slideTo(2, 1000);
                                }}
                                className={`element-icon${selected==='earth' ? "" : " element-icon-inactive"}`} />
                            <AirMain
                                onClick={()=> {
                                    setSelected('air')
                                    setElementTitle('Воздух');
                                    characterSwiper.slideTo(3, 1000);
                                }}
                                className={`element-icon${selected==='air' ? "" : " element-icon-inactive"}`} />
                        </div>
                        <p className="">{elementTitle}</p>
                    </div>

                    
                    <div className={`elements-character ${selected} ${gender}`}>
                        <p>Персонаж</p>
                        <Swiper
                            onSwiper={setCharacterSwiper}
                            onSlideChange={(swiper) => {
                                switch (swiper.realIndex) {
                                    case 0:
                                        setSelected('fire')
                                        setElementTitle('Огонь')
                                        break;
                                    case 1:
                                        setSelected('water')
                                        setElementTitle('Вода')
                                        break;
                                    case 2:
                                        setSelected('earth')
                                        setElementTitle('Земля')
                                        break;
                                    case 3:
                                        setSelected('air')
                                        setElementTitle('Воздух');
                                        break;
                                    default:
                                        break;
                                }
                            }}
                            slidesPerView={"1"}
                            centeredSlides={true}
                            spaceBetween={30}
                            className="mySwiper"
                        >
                            <SwiperSlide> <img src={gender==="man" ? FireMan : FireWoman} alt='character'/> </SwiperSlide>
                            <SwiperSlide> <img src={gender==="man" ? WaterMan : WaterWoman} alt='character'/> </SwiperSlide>
                            <SwiperSlide> <img src={gender==="man" ? EarthMan : EarthWoman} alt='character'/> </SwiperSlide>
                            <SwiperSlide> <img src={gender==="man" ? AirMan : AirWoman} alt='character'/> </SwiperSlide>
                        </Swiper>
                        
                        <div>
                            <button 
                                onClick={()=>{setGender('man')}}
                                className={`element-btn-gender ${gender==='man' ? " active" : ""}`}>
                                Мужчина
                            </button>
                            <button 
                                onClick={()=>{setGender('woman')}}
                                className={`element-btn-gender ${gender==='woman' ? " active" : ""}`}>
                                Женщина
                            </button>
                        </div>
                    </div>

                    


                    {/* ANIMALS */}
                    <p className="my-5">Вы можете выбрать себе одно из питомцев для получения особых магических возможностей, которые помогут при выполнение тех, или иных заданий и упражнений. У каждого питомца свои особые возможности.</p>

                    <div className={`elements-animal ${animal}`}>
                        <p>Питомец</p>
                        <Swiper
                            onSwiper={setAnimalSwiper}
                            onSlideChange={(swiper) => {
                                switch (swiper.realIndex) {
                                    case 0:
                                        setAnimal('fox');
                                        break;
                                    case 1:
                                        setAnimal('wolf');
                                        break;
                                    case 2:
                                        setAnimal('panda');
                                        break;
                                    case 3:
                                        setAnimal('eagle');
                                        break;
                                    default:
                                        break;
                                }
                            }}
                            slidesPerView={"1"}
                            centeredSlides={true}
                            spaceBetween={30}
                            className="mySwiper"
                        >
                            <SwiperSlide> <img src={FireAnimal} alt='character'/> </SwiperSlide>
                            <SwiperSlide> <img src={WaterAnimal} alt='character'/> </SwiperSlide>
                            <SwiperSlide> <img src={EarthAnimal} alt='character'/> </SwiperSlide>
                            <SwiperSlide> <img src={AirAnimal} alt='character'/> </SwiperSlide>
                        </Swiper>
                        
                        <div>
                            <button 
                                onClick={()=>{
                                                animalSwiper.slideTo(0, 1000);
                                                setAnimal('fox');
                                            }}
                                className={`element-btn-animal ${animal==='fox' ? " active" : ""}`}>
                                Лиса
                            </button>
                            <button 
                                onClick={()=>{
                                                animalSwiper.slideTo(1, 1000);
                                                setAnimal('wolf');
                                            }}
                                className={`element-btn-animal ${animal==='wolf' ? " active" : ""}`}>
                                Волк
                            </button>
                            <button 
                                onClick={()=>{
                                                animalSwiper.slideTo(2, 1000);
                                                setAnimal('panda');
                                            }}
                                className={`element-btn-animal ${animal==='panda' ? " active" : ""}`}>
                                Панда
                            </button>
                            <button 
                                onClick={()=>{
                                                animalSwiper.slideTo(3, 1000);
                                                setAnimal('eagle');
                                            }}
                                className={`element-btn-animal ${animal==='eagle' ? " active" : ""}`}>
                                Орел
                            </button>
                        </div>
                    </div>
                </div>
                
            </section>

            
            
        </>
    )
}

export default VElementsOfArcteach;






