import { useState } from "react";
import "./VHowItWorks.css";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// Video
import ReactPlayer from 'react-player/lazy'

const VHowItWorks = () => {
    const [role, setRole] = useState('student');
    const [roleSwiper, setRoleSwiper] = useState(null);

    const [parentVideoUrl, setParentVideoUrl] = useState('');
    const [teacherVideoUrl, setTeacherVideoUrl] = useState('');

    return(
        <>
        <section className="how_it_works-section">
            <h1 className="subheader-title">Как это работает</h1>

            <div className="how_it_works-role_selector">
                <p 
                    onClick={()=>{
                                    setRole('student');
                                    roleSwiper.slideTo(0, 1000);
                                }}
                    className={`how_it_works-role_main${role==='student' ? " active" : ""}`}
                >Ученик</p>
                <p 
                    onClick={()=>{
                                    setRole('parent');
                                    roleSwiper.slideTo(1, 1000);
                                    !parentVideoUrl && setParentVideoUrl('./parent.mp4');
                                }}
                    className={`how_it_works-role_main${role==='parent' ? " active" : ""}`}
                >Родитель</p>
                <p 
                    onClick={()=>{
                                    setRole('teacher');
                                    roleSwiper.slideTo(2, 1000);
                                    !teacherVideoUrl && setTeacherVideoUrl('./teacher.mp4');
                                }}
                    className={`how_it_works-role_main${role==='teacher' ? " active" : ""}`}
                >Учитель</p>
            </div>

            <Swiper
                onSwiper={setRoleSwiper}
                onSlideChange={(swiper) => {
                    switch (swiper.realIndex) {
                        case 0:
                            setRole('student');
                            break;
                        case 1:
                            setRole('parent');
                            !parentVideoUrl && setParentVideoUrl('./parent.mp4');
                            break;
                        case 2:
                            setRole('teacher');
                            !teacherVideoUrl && setTeacherVideoUrl('./teacher.mp4');
                            break;
                        default:
                            break;
                    }
                }}
                slidesPerView={"1"}
                centeredSlides={true}
                spaceBetween={30}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className={`how_it_works-role_info`}>
                        <p>Student Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <ReactPlayer
                            className='react-player'
                            url="./student.mp4"
                            width='100%'
                            height='100%'
                            controls={true}
                        />
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={`how_it_works-role_info`}>
                        <p>Parent Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <ReactPlayer
                            className='react-player'
                            url={parentVideoUrl}
                            width='100%'
                            height='100%'
                            controls={true}
                        />
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={`how_it_works-role_info`}>
                        <p>Teacher Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <ReactPlayer
                            className='react-player'
                            url={teacherVideoUrl}
                            width='100%'
                            height='100%'
                            controls={true}
                        />
                    </div>
                </SwiperSlide>
            </Swiper>

            

            

            
            
            

            


        </section>
    </>
    )
    
};

export default VHowItWorks;