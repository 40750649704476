import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "./../../assets/logo/main.svg";
import "./VNavBar.css";

const VNavBar = () => {
    const [hamburger, setHamburger] = useState(false);
    const [navLinks, setNavLinks] = useState(false);
    const [liFade, setLiFade] = useState(false);
    
    return (
        <>
            <nav className="noselect">
                <div className="container mx-auto flex">
                    {/* LOGO */}
                    <div className="logo">
                        <NavLink className="logo-btn" to="/">
                            <img src={logo} alt="ArcTeach Logo"/>
                        </NavLink>
                    </div>

                    {/* HAMBIRGER */}
                    <div onClick={() => {
                        setHamburger(!hamburger);
                        setNavLinks(!navLinks);
                        setLiFade(!liFade);
                        }}
                        className={"hamburger" + (hamburger ? " toggle" : "")}
                    >
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>

                    {/* BUTTONS */}
                    <div className="nav-buttons">
                        <NavLink to="/login"><button className="login-button" href="#">Вход</button></NavLink>
                        <NavLink to="/sign-up"><button className="join-button" href="#">Регистрация</button></NavLink>
                    </div>

                    {/* MENU */}
                    <ul className={"nav-links" + (navLinks ? " open" : "")}>
                        {/* LEFT */}
                        <div className="nav-left">
                            
                            <li className={navLinks ? " fade" : ""}><NavLink 
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/">Главная
                                </NavLink>
                            </li>

                            <li className={navLinks ? " fade" : ""}>
                                <NavLink
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/how-it-works">Как это работает
                                </NavLink>
                            </li>

                            <li className={navLinks ? " fade" : ""}>
                                <NavLink
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/price">Прайс
                                </NavLink>
                            </li>

                            <li className={navLinks ? " fade" : ""}>
                                <NavLink
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/elements-of-arcteach">Стихии ArcTeach
                                </NavLink>
                            </li>

                            <li className={navLinks ? " fade" : ""}>
                                <NavLink
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/about">О нас
                                </NavLink>
                            </li>
                            <li className={navLinks ? " fade" : ""}>
                                <NavLink 
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/courses">Курсы
                                </NavLink>
                            </li>

                            <li className={navLinks ? " fade" : ""}>
                                <NavLink 
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/faq">FAQ
                                </NavLink>
                            </li>
                            
                            <li className={navLinks ? " fade" : ""}>
                                <NavLink 
                                    onClick={() => {setHamburger(!hamburger); setNavLinks(!navLinks); setLiFade(!liFade);}} 
                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')} 
                                    to="/contacts">Контакты
                                </NavLink>
                            </li>
                        </div>
                        {/* RIGHT */}
                        <div className="nav-right">
                            <NavLink to="/login"><button className="login-button" href="#">Вход</button></NavLink>
                            <NavLink to="/sign-up"><button className="join-button" href="#">Регистрация</button></NavLink>
                        </div>
                        
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default VNavBar;