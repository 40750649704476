import { createSlice } from '@reduxjs/toolkit'

export const offcanvasUserSlice = createSlice({
    name: 'offcanvasUser',
    initialState: {
        offcanvasUserVisibility: false,
    },
    reducers: {
        setOffcanvasUserToggleVisibility: (state, action) =>  {
                                                            state.offcanvasUserVisibility = action.payload
                                                        },

    },
})

// Action creators are generated for each case reducer function
export const { setOffcanvasUserToggleVisibility } = offcanvasUserSlice.actions

// useSelector()
export const selectOffcanvasUser = (state) => state.offcanvasUser;

export default offcanvasUserSlice.reducer