import { createSlice } from "@reduxjs/toolkit"; 
import decode from "jwt-decode";

const initialStateValue = {
                            user: null,
                            access_token: null
                        };

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialStateValue,
    reducers: {
        setCredentials: (state, action) => {
            // console.log(1);
            // console.log(action.payload);
            // console.log(1);
            // localStorage.setItem("auth",
            //     JSON.stringify({
            //         userLogin: true,
            //         access_token: action.payload.access_token,
            //         refresh_token: action.payload.refresh_token
            //     })
            // )
            let { user } = decode(action.payload);
            state.user = user;
            state.access_token = action.payload;

            // let result = localStorage.getItem("user");
            // console.log(result);
            // console.log(JSON.parse(result).access_token);
            // console.log(JSON.parse(result).userLogin)
        },
        resetCredentials: () => initialStateValue
        
    }
})

// Action creators are generated for each case reducer function
export const { setCredentials, resetCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.access_token;
export const selectCurrentUser = (state) => state.auth.user;