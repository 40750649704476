import { configureStore } from "@reduxjs/toolkit";

// api
import { apiSlice } from "./apiSlice";
// user
import authReducer from "./../features/auth/authSlice";
// user
import userReducer from "./../features/user";
// aside
import asideReducer from "./../features/aside"
// offcanvas USER
import offcanvasUserReducer from "../comp_portal/offcanvas/POffcanvasUserSlice";
// offcanvas NOTIFICATIONS
import offcanvasNotificationsReducer from "../comp_portal/offcanvas/POffcanvasNotificationsSlice";


export default configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        aside: asideReducer,
        user: userReducer,
        offcanvasUser: offcanvasUserReducer,
        offcanvasNotifications: offcanvasNotificationsReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
});