import './POffcanvasNotifications.css';
import ClickAwayListener from 'react-click-away-listener';

// ICONS
import { GrClose } from 'react-icons/gr';

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useGetGoodsQuery } from '../../app/apiSlice';

// OFFCANVAS NOTIFICATIONS
import { setOffcanvasNotificationsToggleVisibility } from "./POffcanvasNotificationsSlice";



const POffcanvasNotifications = () => {
    const dispatch = useDispatch();

    const { data, error, isLoading, isFetching, isSuccess, refetch } = useGetGoodsQuery();

    // OFFCANVAS USER
    const { offcanvasNotificationsVisibility } = useSelector( (state) => state.offcanvasNotifications);

    const handleClickAway = (e) => {
        if(e.target.closest('#offcanvasNotifications')==null && offcanvasNotificationsVisibility) dispatch(setOffcanvasNotificationsToggleVisibility(false))
    };



    return(
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <section className={'offCanvas-user_profile' + (offcanvasNotificationsVisibility ? ' active' : '')}>
                    <button onClick={()=>dispatch(setOffcanvasNotificationsToggleVisibility(!offcanvasNotificationsVisibility))} className='offcanvas-close-button'><GrClose /></button>
                    <h1>Notifications</h1>
                    {data?.map( (record, i) => {
                        console.log(2);
                    return (
                        <div key={i}>
                            <h1 className="font-bold" >{record.name}</h1>
                            <p>Comments:</p>
                            <ul>
                                {record.comments.map((comment, j)=>{
                                    return <li key={j} className="ml-5">{comment.username}: {comment.text}</li>
                                })}
                                
                            </ul>
                        </div>
                    )
                })}
                </section>
                
            </div>
        </ClickAwayListener>
    )
}

export default POffcanvasNotifications;