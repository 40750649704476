import "./PSideMenu.css";
import { NavLink } from "react-router-dom";
import ClickAwayListener from 'react-click-away-listener';
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { setAsideFixed, setAsideOpen, setAsideClosed, selectAside } from "../../features/aside";
// ICONS
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { MdDashboard, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FiSettings } from 'react-icons/fi';
// IMAGES
import logo from "./../../assets/logo/main.svg";

const ConditionalLink = ({ children, to, condition, subitem, setLiOpen=null}) => {
    let css = (subitem ? 'block-aside-menu_subitem' : 'block-aside-menu_item') +  ' text-lg gap-x-3 px-4 cursor-pointer hover:bg-stone-500/25 rounded-md';
    return (
        (!!condition && to)    
        ? <NavLink to={to} 
            className={ 
                        ( { isActive }) => (css + (isActive ? ' active bg-stone-500/25' : ' inactive'))
                     }>
                {children}
            </NavLink>
        : <div className={css}>{children}</div>
    )
}

const MenuComp = ({menu, asideOpen, asideFixed}) => {

    return(
        <li onClick={(e) => {e.target.closest('li').classList.toggle('open')}} 
            className={`${menu.newSection && 'mt-5'} text-gray-300`} >
            <ConditionalLink to={menu.path} condition={menu.path}>
                    <div className="flex items-center gap-x-3">
                    {menu.icon}
                    {(asideOpen || asideFixed) && menu.title}
                </div>
                {(asideOpen || asideFixed) && menu.children && <MdOutlineKeyboardArrowRight className="menu-item-arrow" /> }
            </ConditionalLink>
                
            {menu.children && 
                <ul className="menu-item-content pl-7">
                    {menu.children.map( (sub, i) => (
                        <li key={i} className="pl-3">
                            <ConditionalLink to={sub.path} condition={sub.path}>
                                <div className="flex items-center gap-x-3">
                                    {sub.icon}
                                    {sub.title}
                                </div>
                            </ConditionalLink>
                            
                        </li>
                    ))}
                </ul>
            }
        

        </li>
    )
}

const PSideMenu = () => {
    const { asideFixed, asideOpen } = useSelector(selectAside);
    const dispatch = useDispatch();

    // CLICK AWAY
    const handleClickAway = (e) => {
        if(e.target.closest('#asideNav')==null && asideFixed && !asideOpen) dispatch(dispatch(setAsideFixed(false)))
    };

    const Menus =   [
                        {   
                            title: "Dashboard",   
                            icon: <MdDashboard className="text-xl" />,
                            path: "/test",
                            newSection: true
                        },
                        {   
                            title: "Smth",   
                            icon: <MdDashboard className="text-xl" />,
                            path: "/smth",
                            newSection: false
                        },
                        { 
                            title: "Settings",    
                            icon: <FiSettings className="text-xl" />,
                            newSection: false,
                            children:   [
                                            { 
                                                title: "Dashboard",
                                                icon: <MdDashboard />,
                                                path: "/sdf"
                                            },
                                            { 
                                                title: "Dashboard",
                                                icon: <MdDashboard />,
                                                path: "/asd"
                                            }
                                        ]
                        },
                        { 
                            title: "Settings",    
                            icon: <FiSettings className="text-xl" />,
                            newSection: true,
                            children:   [
                                            { 
                                                title: "Dashboard",
                                                icon: <MdDashboard />,
                                                path: "/dashboard"
                                            },
                                            { 
                                                title: "Dashboard",
                                                icon: <MdDashboard />,
                                                path: "/settings"
                                            }
                                        ]
                        },
                    ];
    
    return(
        <ClickAwayListener onClickAway={handleClickAway}>
                <aside 
                    onMouseEnter={ () => !asideFixed && dispatch(setAsideOpen())}
                    onMouseLeave={ () => !asideFixed && dispatch(setAsideClosed())}
                    className={'noselect block-aside-main duration-300' + (asideOpen || asideFixed ? ' open' : '')}>
                        
                    {/* HEADER */}
                    <div className="block-aside-header inline-flex items-center justify-between text-white w-full px-5 py-3">
                        <div className="h-full">
                            <NavLink className='flex items-center h-full' to="/dashboard">
                                <img className='h-full border-2 border-white' src={logo} alt="ArcTeach Logo"/>
                                {(asideOpen || asideFixed) && <h1 className="block-aside-company-name text-xl ml-4">ArcTeach</h1>}
                            </NavLink>
                        </div>
                        {(asideOpen || asideFixed) && <button onClick={()=>{dispatch(setAsideFixed(!asideFixed))}}>{ asideFixed ? <BsToggleOn className="block-aside-arrow-right text-2xl" /> : <BsToggleOff className="block-aside-arrow-right text-2xl" />} </button>}
                    </div>

                    {/* BODY */}    
                    <div className="px-3 mt-5 relative">

                        <ul>
                            {Menus.map((menu, i) => <MenuComp key={i} menu={menu} asideOpen={asideOpen} asideFixed={asideFixed} />)}
                        </ul>

                    
                    </div>

                    
                    
                    
                    
                </aside>
        </ClickAwayListener>
    )
}

export default PSideMenu;