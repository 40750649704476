import './VFaq.css';
import VFaqItem from './VFaqItem';

const VFaq = () => {
    const data = [
                    {
                    title: "One", 
                    content: `Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. 
                            Ut enim ad minim veniam, quis 
                            nostrud exercitation ullamco laboris 
                            nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit 
                            in voluptate velit esse cillum dolore 
                            eu fugiat nulla pariatur. Excepteur 
                            sint occaecat cupidatat non proident, 
                            sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.`
                    }, 
                    {
                    title: "Two", 
                    content: `Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. 
                            Ut enim ad minim veniam, quis 
                            nostrud exercitation ullamco laboris 
                            nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit 
                            in voluptate velit esse cillum dolore 
                            eu fugiat nulla pariatur. Excepteur 
                            sint occaecat cupidatat non proident, 
                            sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.`
                    },
                    {
                    title: "Three", 
                    content: `Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. 
                            Ut enim ad minim veniam, quis 
                            nostrud exercitation ullamco laboris 
                            nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit 
                            in voluptate velit esse cillum dolore 
                            eu fugiat nulla pariatur. Excepteur 
                            sint occaecat cupidatat non proident, 
                            sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.`
                    },
                    {
                    title: "Four", 
                    content: `Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. 
                            Ut enim ad minim veniam, quis 
                            nostrud exercitation ullamco laboris 
                            nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit 
                            in voluptate velit esse cillum dolore 
                            eu fugiat nulla pariatur. Excepteur 
                            sint occaecat cupidatat non proident, 
                            sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.`
                    },
                    {
                    title: "Five", 
                    content: `Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. 
                            Ut enim ad minim veniam, quis 
                            nostrud exercitation ullamco laboris 
                            nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit 
                            in voluptate velit esse cillum dolore 
                            eu fugiat nulla pariatur. Excepteur 
                            sint occaecat cupidatat non proident, 
                            sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.`
                    },
                    {
                    title: "Six", 
                    content: `Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. 
                            Ut enim ad minim veniam, quis 
                            nostrud exercitation ullamco laboris 
                            nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit 
                            in voluptate velit esse cillum dolore 
                            eu fugiat nulla pariatur. Excepteur 
                            sint occaecat cupidatat non proident, 
                            sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.`
                    },
                    {
                    title: "Seven", 
                    content: `Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt 
                            ut labore et dolore magna aliqua. 
                            Ut enim ad minim veniam, quis 
                            nostrud exercitation ullamco laboris 
                            nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit 
                            in voluptate velit esse cillum dolore 
                            eu fugiat nulla pariatur. Excepteur 
                            sint occaecat cupidatat non proident, 
                            sunt in culpa qui officia deserunt 
                            mollit anim id est laborum.`
                    }
                ];
    const sections = data.map((item, i) => <VFaqItem key={i} item={item} />);

    return(
        <section className="faq-section">
            
            <div class="flex flex-wrap mb-4">
                <div class="w-full md:w-1/3 flex justify-center items-center my-5">
                    <div className='flex flex-col'>
                    <h1 className="subheader-title">FAQ</h1>
                        <p className='mt-5'>Не нашли ответа на интересующий Вас вопрос? Задайте его нам и мы ответим в самое ближайшее время.</p>
                        <p className='mt-2 mb-10'>Вы также можете воспользоваться услугуами виртуальной помощницы Arcana.</p>
                        <button 
                            className="recover-btn-recover py-2">
                                Написать нам
                        </button>
                        <button 
                            className="recover-btn-recover py-2 mt-2">
                                Помощница Arcana
                        </button>
                    </div>
                    
                </div>
                <div class="w-full md:w-2/3 my-5">
                    <div className="faq-accordion">
                        {sections}
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default VFaq;