import './POffcanvasUser.css';
import ClickAwayListener from 'react-click-away-listener';

// ICONS
import { GrClose } from 'react-icons/gr';
import { BiLogOut } from 'react-icons/bi';

// REDUX
import { useDispatch, useSelector } from "react-redux";

// USER
import { useLogoutMutation } from '../../features/auth/authApiSlice';

// OFFCANVAS USER
import { setOffcanvasUserToggleVisibility } from "./POffcanvasUserSlice";
// AUTH
import { selectCurrentUser } from '../../features/auth/authSlice';
import { resetCredentials } from '../../features/auth/authSlice';


const POffcanvasUser = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const [logout, {data: result, isLoading, isSuccess, isError, error}] = useLogoutMutation();

    // OFFCANVAS USER
    const { offcanvasUserVisibility } = useSelector( (state) => state.offcanvasUser);

    const handleClickAway = (e) => {
        if(e.target.closest('#offcanvasUser')==null && offcanvasUserVisibility) dispatch(setOffcanvasUserToggleVisibility(false))
    };

    const handleLogout = async () => {
        await logout();        
        dispatch(resetCredentials());
    }

    return(
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <section className={'offCanvas-user_profile' + (offcanvasUserVisibility ? ' active' : '')}>
                    <button onClick={()=>dispatch(setOffcanvasUserToggleVisibility(!offcanvasUserVisibility))} className='offcanvas-close-button'><GrClose /></button>
                    <h1>User Profile</h1>
                    <h2>{user.first_name} {user.last_name}</h2>
                    <p>{user.email}</p>
                    <button 
                        className='inline-flex items-center px-4 py-2 bg-gray-300 rounded mt-5 cursor-pointer' 
                        onClick={handleLogout}>
                            <BiLogOut className="mr-2" />Log Out
                    </button>
                </section>
            </div>
        </ClickAwayListener>
    )
}

export default POffcanvasUser;