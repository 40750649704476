import "./VPrice.css";
import { useEffect, useState } from "react";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// Icons
import { BsCheckLg } from 'react-icons/bs';


const VPrice = () => {
    const [priceType, setPriceType] = useState('free');
    const [priceSwiper, setPriceSwiper] = useState(null);
    const [paymentDuration, setPaymentDuration] = useState('month');
    const [priceToPay, setPriceToPay] = useState(9990);

    const breakpointMd = 1024;
    const breakpointSm = 600;

    const [isDesktop, setDesktop] = useState(window.innerWidth > breakpointMd);
    const [isMobile, setMobile] = useState(window.innerWidth < breakpointSm);
    const updateMedia = () => {
        console.log(1)
        setDesktop(window.innerWidth > breakpointMd);
        setMobile(window.innerWidth < breakpointSm);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const bundleBasic = <div className="price-type_block">
                            <button 
                                onClick={()=>{
                                                setPriceType('basic');
                                                priceSwiper.slideTo(0, 1000);
                                            }}
                                className={`price-btn${priceType==='basic' ? " active" : ""}`}
                            >Базовый
                            </button>
                            <p className="price-points"><BsCheckLg className="check included" />Словарь</p>
                            <p className="price-points"><BsCheckLg className="check included" />Игры</p>
                            <p className="price-points"><BsCheckLg className="check included" />Теория</p>
                            <p className="price-points"><BsCheckLg className="check included" />Упражнения</p>
                            <p className="price-points"><BsCheckLg className="check" />Статистика</p>
                            <p className="price-points"><BsCheckLg className="check" />Аудио материалы</p>
                            <p className="price-points"><BsCheckLg className="check" />Видео материалы</p>
                        
                            <div>
                                <button 
                                    onClick={()=> {
                                                    setPaymentDuration('month');
                                                    setPriceToPay(9990);
                                            }}
                                    className={`price-btn${paymentDuration==='month' ? " active" : ""}`}
                                ><span style={{"font-weight": "500"}}>за 1 месяц</span> <br></br> 9 990 тг / мес
                                </button>
                                <button 
                                    onClick={()=> {
                                                    setPaymentDuration('year');
                                                    setPriceToPay(6990*12);
                                            }}
                                    className={`price-btn${paymentDuration==='year' ? " active" : ""}`}
                                ><span style={{"font-weight": "500"}}>за 1 год</span> <br></br> 6 990 тг / мес
                                </button>
                            </div>

                            <button className="price-btn-buy active">
                                <span style={{"font-weight": "500"}}>
                                    { priceToPay.toLocaleString('ru') } тг
                                </span>
                                <br></br>КУПИТЬ
                            </button>

                        </div>;

    const bundleFree =  <>
                            <div className="price-type_block">
                                <button className="price-btn active">Бесплатный</button>
                                <p className="price-points"><BsCheckLg className="check included" />Словарь</p>
                                <p className="price-points"><BsCheckLg className="check included" />Игры</p>
                                <p className="price-points"><BsCheckLg className="check" />Теория</p>
                                <p className="price-points"><BsCheckLg className="check" />Упражнения</p>
                                <p className="price-points"><BsCheckLg className="check" />Статистика</p>
                                <p className="price-points"><BsCheckLg className="check" />Аудио материалы</p>
                                <p className="price-points"><BsCheckLg className="check" />Видео материалы</p>
                                
                                <div>
                                    <button 
                                        onClick={()=> {
                                                setPaymentDuration('month');
                                                setPriceToPay(9990);
                                        }}
                                        className={`price-btn${paymentDuration==='month' ? " active" : ""}`}
                                    ><span style={{"font-weight": "500"}}>за 1 месяц</span> <br></br> 0 тг / мес
                                    </button>
                                    <button 
                                        onClick={()=> {
                                                        setPaymentDuration('year');
                                                        setPriceToPay(6990*12);
                                                }}
                                        className={`price-btn${paymentDuration==='year' ? " active" : ""}`}
                                    ><span style={{"font-weight": "500"}}>за 1 год</span> <br></br> 0 тг / мес
                                    </button>
                                </div>

                                <button className="price-btn-buy active">
                                    <span style={{"font-weight": "500"}}>
                                        0 тг
                                    </span>
                                    <br></br>БЕСПЛАТНО
                                </button>
                            </div>

                            
                        </>;
    
    const bundleStandard =  <div className="price-type_block">
                                <button 
                                    onClick={()=>{
                                        setPriceType('standard');
                                        priceSwiper.slideTo(2, 1000);
                                    }}
                                    className={`price-btn${priceType==='standard' ? " active" : ""}`}
                                >Стандартный
                                </button>
                                <p className="price-points"><BsCheckLg className="check included" />Словарь</p>
                                <p className="price-points"><BsCheckLg className="check included" />Игры</p>
                                <p className="price-points"><BsCheckLg className="check included" />Теория</p>
                                <p className="price-points"><BsCheckLg className="check included" />Упражнения</p>
                                <p className="price-points"><BsCheckLg className="check included" />Статистика</p>
                                <p className="price-points"><BsCheckLg className="check" />Аудио материалы</p>
                                <p className="price-points"><BsCheckLg className="check" />Видео материалы</p>
                            </div>;

    const bundleVip =   <div className="price-type_block">
                            <button 
                                onClick={()=>{
                                    setPriceType('pro');
                                    priceSwiper.slideTo(3, 1000);
                                }}
                                className={`price-btn${priceType==='pro' ? " active" : ""}`}
                            >VIP
                            </button>
                            <p className="price-points"><BsCheckLg className="check included" />Словарь</p>
                            <p className="price-points"><BsCheckLg className="check included" />Игры</p>
                            <p className="price-points"><BsCheckLg className="check included" />Теория</p>
                            <p className="price-points"><BsCheckLg className="check included" />Упражнения</p>
                            <p className="price-points"><BsCheckLg className="check included" />Статистика</p>
                            <p className="price-points"><BsCheckLg className="check included" />Аудио материалы</p>
                            <p className="price-points"><BsCheckLg className="check included" />Видео материалы</p>
                        </div>;
    
    return(
        <>
            <section className="price-section text-center">
                <h1 className="subheader-title">Прайс</h1>
                

                


                    {!isDesktop
                        ?   <Swiper
                                onSwiper={setPriceSwiper}
                                onSlideChange={(swiper) => {
                                    switch (swiper.realIndex) {
                                        case 0:
                                            setPriceType('basic');
                                            break;
                                        case 1:
                                            setPriceType('free');
                                            break;
                                        case 2:
                                            setPriceType('standard');
                                            break;
                                        case 3:
                                            setPriceType('pro');
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                                slidesPerView={"auto"}
                                centeredSlides={true}
                                spaceBetween={10}
                                className="mySwiper"
                                initialSlide={1}
                            >
                                <SwiperSlide className={`${isMobile ? "mobile" : !isDesktop ? "device" : ""}`}>{bundleBasic}</SwiperSlide>
                                <SwiperSlide className={`${isMobile ? "mobile" : !isDesktop ? "device" : ""}`}>{bundleFree}</SwiperSlide>
                                <SwiperSlide className={`${isMobile ? "mobile" : !isDesktop ? "device" : ""}`}>{bundleStandard}</SwiperSlide>
                                <SwiperSlide className={`${isMobile ? "mobile" : !isDesktop ? "device" : ""}`}>{bundleVip}</SwiperSlide>
                            </Swiper>
                        :   <>
                                <div className="price-type_selector">
                                    {bundleBasic}
                                    {bundleFree}
                                    {bundleStandard}
                                    {bundleVip}
                                </div>
                            </>
                    }    

            </section>
        </>
    )
}

export default VPrice;