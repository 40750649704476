import { useState } from "react";
import { MdArrowForwardIos } from 'react-icons/md';

const VFaqItem = ({item}) => {
    const [open, setOpen] = useState(false);

    return(
        <div>
            <div onClick={()=> setOpen(!open)} 
                className={open
                    ? "title active"
                    : "title"}
                >
                <span className="title-text">
                    {item.title}
                </span>
                <MdArrowForwardIos className={open 
                    ? "icon icon-rotate-90" 
                    : "icon"}
                />
            </div>
            <div className={open 
                ? "content content-open" 
                : "content"}
            >
            <div className={open 
                ? "content-text content-text-open" 
                : "content-text"}
            > {item.content}
            </div>
            </div>
        </div>
    )
}

export default VFaqItem;