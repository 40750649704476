import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRefreshTokenMutation } from "./authApiSlice";
import { selectCurrentToken } from "./authSlice";
import { setCredentials } from "./../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const PersistLogin = () => {
    const [refreshed, setRefreshed] = useState(false);
    var token = useSelector(selectCurrentToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [refreshToken, {data: result, isLoading, isSuccess, isError, error}] = useRefreshTokenMutation();

    useEffect( ()=> {
        setRefreshed(true);
        if(!token && !result && !refreshed){
            refreshToken();
        }

        if(isSuccess){
            // console.log(result);
            dispatch(setCredentials(result?.data?.access_token));
            navigate("/dashboard", { replace: false })
            // console.log('here ' + token);
        }
    }, [result, token])
    
    return (
        refreshed || token || isError
            ? <Outlet />
            : <p>Loading...</p>
    );

    
}






export default PersistLogin;