import { apiSlice } from "./../../app/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/api/auth',
                method: 'POST',
                body: credentials
            })
        }),
        refreshToken: builder.mutation({
            query: () => ({
                url: '/api/refresh',
                method: 'POST'
            })
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/api/logout',
                method: 'POST'
            })
        }),
    })
})

export const {
    useLoginMutation, useRefreshTokenMutation, useLogoutMutation
} = authApiSlice;