import { createSlice } from '@reduxjs/toolkit'

export const offcanvasNotificationsSlice = createSlice({
    name: 'offcanvasNotifications',
    initialState: {
        offcanvasNotificationsVisibility: false,
    },
    reducers: {
        setOffcanvasNotificationsToggleVisibility: (state, action) =>  {
                                                            state.offcanvasNotificationsVisibility = action.payload
                                                        },

    },
})

// Action creators are generated for each case reducer function
export const { setOffcanvasNotificationsToggleVisibility } = offcanvasNotificationsSlice.actions

// useSelector()
export const selectOffcanvasNotifications = (state) => state.offcanvasNotifications;

export default offcanvasNotificationsSlice.reducer