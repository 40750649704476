import './VSignUp.css';
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { useRef } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { setCredentials } from "../../features/user";
import { useUserSignupMutation } from '../../app/apiSlice';

// custom
import { validateEmail } from "../../customHooks/validations";

const VSignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const emailRef = useRef();
    // const errRef = useRef();
    // useEffect( () => {
    //     emailRef.current.focus();
    // }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userSignup, {data: userData, isSuccess, isError, error}] = useUserSignupMutation();

    useEffect( () => {
        if(isSuccess){
            if(userData.status==='ok'){
                dispatch(setCredentials(userData.data));
                navigate('../dashboard', { replace: false })
            }
            
        } else if(isError){
            let message = error?.data?.message?.map((msg,i)=><li key={i}>{msg}</li>);
            console.log(error.data.message)
            setErrorMsg(<ul className="text-red-500">{message}</ul>); 
        }
    }, [isSuccess, isError, error, navigate, userData, dispatch])

    const onSignupClicked = async (e) => {
        e.preventDefault();
        var errors = [];
        
        // EMAIL VALIDATION
        if(!email){
            errors.push('email cannot be empty');
        }else{     
            var isValidEmail = validateEmail(email);
            if(!isValidEmail){
                errors.push('invalid email');
            }
        }

        // PASSWORD VALIDATION
        if(!password){
            errors.push('password cannot be empty');
        }else{
            var isEnoughLengthPassword = password.length>=6;
            if(!isEnoughLengthPassword){
                errors.push('password must have at least 6 characters');
            }
        }
            
        if(isValidEmail && isEnoughLengthPassword){
            // VALID FORMAT
            await userSignup({email, password}).unwrap();
        }    
        let list_of_errors = errors.map( (txt, i) => (
                <li key={i}>{txt}</li>
            )
        )
        setErrorMsg(<ul className="text-red-500">{list_of_errors}</ul>); 
    }

    return(
        <>
            <section className="signup-section">
            <div className="signup-pattern"></div>
                <div className="signup-frame">
                    <h1>
                        <span className="text-5xl company-name-arc-color">Arc</span>
                        <span className="text-5xl company-name-teach-color">Teach</span>
                    </h1>
                    <p className="text-xl mt-2">Создать аккаунт</p>
                    {errorMsg}
                    <form className="flex flex-col">
                        <input 
                            autoComplete='new-password' 
                            ref={emailRef}
                            className='signup-email_input my-3' 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            type={'email'} 
                            placeholder={'эл. почта'}/>
                        <input 
                            autoComplete='new-password' 
                            className='signup-password_input my-3' 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            type={'password'} 
                            placeholder={'пароль'}/>

                        <button 
                            onClick={(e) => onSignupClicked(e)} 
                            className="signup-btn-signup py-2 mt-2">
                                Зарегистрироваться
                        </button>

                        <p className="text-gray-600 mt-10">Уже есть аккаунт? <NavLink to={'/login'} className="hover:text-black underline" >Вход</NavLink></p>
                        <p className="text-gray-600 mt-2">Регистрируясь Вы соглашаетесь с <br></br><NavLink to={'/terms-of-use'} className="hover:text-black underline" >правилами и условиями</NavLink></p>
                    </form>
                </div>
            </section>
            
        </>
    )
}

export default VSignUp;